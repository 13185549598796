<template>
  <div :class="toplabel==''?'main-app-timepicker-inline':'main-app-timepicker'">
    <div v-if="toplabel!=''" class="toplabel">{{ toplabel }}</div>
    <app-timepicker v-model="valueLocal" :disabled="disabled" />
  </div>
</template>

<script>
export default {
  name: 'AppTimepickerToplabel',
  param: '',
  props: {
    toplabel: {
      type: String,
      required: false,
      default: '',
    },
    modelValue: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeAction', 'update:modelValue'],

  data() {
    return {
      valueLocal: this.modelValue,
    };
  },

  watch: {
    modelValue() {
      this.valueLocal = this.modelValue;
    },
    time(newVal) {
      this.valueLocal = newVal;
    },
    valueLocal(newVal) {
      this.$emit('changeAction', newVal);
      this.$emit('update:modelValue', newVal);
    },
  },
};
</script>

<style scoped>
.time {
  font-size: 14px;
  height: 30px;
  width: 100%;
}

.time :deep(.btn) {
  padding: 0px 13px;
  margin: -1px 0px;
}

.time :deep(label) {
  padding-top: 3px;
}

.hide {
  display: none;
}

.toplabel {
  background-color: white;
  width: auto !important;
  display: inline;
  position: absolute;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #95A0AA;
  z-index: 1;
  margin-left: 5px;
  margin-top: -7px;
  padding: 0px 3px;
  height: 10px;
  left: 0px;
  white-space: nowrap;
}

.main-app-timepicker {
  padding-top: 7px;
  display: inline-block;
  position: relative;
  margin: 0 2px 0 2px;
}

.main-app-timepicker-inline {
  display: inline-block;
}

</style>
