<template>
  <div
    :style="styleInputText"
    :class="(mini?'mini ':'')+(small?'small ':'')+(toplabel===''?'main-div-app-input-text-inline':'main-div-app-input-text')"
  >
    <app-input-text-with-datalist
      v-if="!multi"
      :ref="'v'+name"
      v-model="valueLocal"
      :toplabel="toplabel"
      :placeholder="placeholder"
      :name="name"
      :small="small"
      :save-manual="saveManual"
      :read-only="readOnly"
      :style-input-text="'width:100%;'+styleInputText"
      :datalist="datalist"
      @blur="onBlur"
    />
    <app-autoresize-textarea-toplabel v-else :toplabel="toplabel" class="textarea" @change-action="$emit('changeAction', $event);" />
    <div class="pict" @click="openFilesSelect()">
      <svg
        :class="multi?'svgmulti':'svg'" width="24" height="24" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.4398 11.05L12.2498 20.24C11.124 21.3658 9.59699 21.9983 8.0048 21.9983C6.41262 21.9983 4.88565 21.3658 3.7598 20.24C2.63396 19.1142 2.00146 17.5872 2.00146 15.995C2.00146 14.4028 2.63396 12.8758 3.7598 11.75L12.9498 2.56C13.7004 1.80944 14.7183 1.38778 15.7798 1.38778C16.8413 1.38778 17.8592 1.80944 18.6098 2.56C19.3604 3.31056 19.782 4.32854 19.782 5.39C19.782 6.45146 19.3604 7.46944 18.6098 8.22L9.4098 17.41C9.03452 17.7853 8.52553 17.9961 7.9948 17.9961C7.46407 17.9961 6.95508 17.7853 6.5798 17.41C6.20452 17.0347 5.99369 16.5257 5.99369 15.995C5.99369 15.4643 6.20452 14.9553 6.5798 14.58L15.0698 6.1"
          stroke="#95A0AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        />
      </svg>
      <div v-if="mini" class="dop-text">Прикрепить файл</div>
    </div>
    <input :ref="'files'" type="file" multiple style="display:none" @change="handleFileUploads()">
    <div v-if="files.length>0" class="files">
      <div v-for="file in files" :key="file.name" class="itemMain">
        <div class="item">
          <span>{{ prepareFileName(file.name) }}</span>
          <svg
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            @click="delfile(file.name)"
          >
            <path
              data-v-1c79ba60="" d="M7.05029 7.05005L16.9498 16.9495" stroke="#B0B1B5" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
            />
            <path
              data-v-1c79ba60="" d="M7.05029 16.95L16.9498 7.05045" stroke="#B0B1B5" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AppInputTextFilesToplabel',
  props: {
    multi: {
      type: Boolean,
      required: false,
      default: false,
    },

    toplabel: {
      type: String,
      required: false,
      default: '',
    },
    validate: {
      type: String,
      required: false,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      required: false,
      type: String,
      default: '',
    },
    styleInputText: {
      type: String,
      required: false,
    },
    datalist: {
      type: Array,
      default: null,
    },
    modelValue: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      default: 'field',
    },
    saveManual: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeAction', 'update:modelValue', 'blur', 'files'],
  data() {
    return {
      down_count: 0,
      e_key: '',
      e_keyCode: 0,
      oldValue: '',
      selStart: 0,
      selEnd: 0,
      files: [],
      deleteFiles: [],
      valueLocal: '',
    };
  },

  watch: {
    value(newVal) {
      this.valueLocal = newVal;
    },
    valueLocal(newVal) {
      this.$emit('update:modelValue', newVal);
    },
  },

  mounted() {
    this.valueLocal = this.$props.modelValue;
  },

  methods: {

    saveToList() {
      this.$refs[`v${this.name}`].saveToList();
    },

    onBlur() {
      this.$emit('blur', this.valueLocal);
    },

    openFilesSelect() {
      this.$refs.files.click();
    },

    handleFileUploads() {
      if (this.mini) {
        this.files = [];
      }
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.files.push(this.$refs.files.files[i]);
      }

      this.$emit('files', this.files);
    },

    delfile(name) {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].name === name) {
          this.files.splice(i, 1);
        }
      }
    },
    change(event) {
      console.error(event);
    },
    prepareFileName(str) {
      if (str.length > 32) {
        return `${str.substr(0, 14)}...${str.substr(-14)}`;
      }
      return str;
    },

  },
};

</script>

<style scoped>
.textarea {
  width: 100%;
}

.hide {
  display: none;
}

.toplabel {
  background-color: white;
  width: auto !important;
  display: inline;
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #95A0AA;
  z-index: 1;
  margin-left: 5px;
  margin-top: -7px;
  padding: 0px 3px;
  height: 10px;
  top: -10px;
}

.main-div-app-input-text-simple {
  display: inline-flex;
  height: 35px;
  padding-top: -5px;

}

.main-div-app-input-text {
  display: inline-block;
  min-height: 35px;
  padding-top: -5px;
  position: relative;
}

datalist {
  position: absolute;
  margin-top: 50px;
  min-height: 30px;
  z-index: 100000;

}

.main-div-app-input-text-inline {
  display: inline-block;
}

.pict {
  position: absolute;
  top: 11px;
  right: 5px;
  background: #ffffff;
  padding-left: 3px;
}

.pict svg {
  position: relative;
  top: 1px;
}

.itemMain {
  display: inline-block;
  position: relative;
  top: -5px;
}

.item {
  font-size: 14px;
  margin: 3px;
  text-align: center;
}

.item svg[data-v-1c79ba60] {
  cursor: pointer;
}

.svg {
  overflow: hidden;
  top: -10px;
  position: relative;
}

.svgmulti {
  overflow: hidden;
  top: -6px;
  position: relative;
}

.files {
}

.files .item {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #586EBF;
}

.textarea {
  max-height: 95px;
  padding-right: 20px;

}

.mini {
  position: relative;
  display: flex;
  height: 30px;
}
.mini .pict{
  left: 0px;
  top: 0px;
  margin:2px;
  position: absolute;
  float: left;
  display: flex;
  color: #586EBF;
  /* H9 14 px roboto regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  cursor: pointer;
}
.mini :deep(.pict svg path){
  stroke: #586EBF;
}
.mini :deep(.main-div-app-input-text-inline input){
  display: none;
}

.mini .dop-text{
  display: flex;
  align-items: center;
  margin-left:3px;
}
.mini .files{
  display: flex;
  align-items: center;
  height: 100%;
}
.mini .itemMain{
  top:0;
}
.mini .files .item{
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 260px;
}
</style>
