<template>
  <div>
    <ul class="pagination">
      <li class="pagination-item" :hidden="isFirstPage">
        <img
          :class="{disabled: isFirstPage}"
          class="previous-icon"
          alt="previous"
          src="https://images.nav.by/img/cdn/system/design_system/chevron-left-black.svg"
          @click="onClickPreviousPage"
        >
      </li>

      <li v-if="firstVisiblePage > 1" class="pagination-item first-item">
        <div class="element-number-page" @click="onClickPage(1)">1</div>
        ...
      </li>

      <li v-for="page in pages" :key="page.name" class="pagination-item">
        <div class="element-number-page" :class="{ active: isPageActive(page.name) }" @click="onClickPage(page.name)">
          {{ page.name }}
        </div>
      </li>

      <li v-if="lastVisiblePage < totalPages" class="pagination-item last-item">
        ...
        <div class="element-number-page" @click="onClickPage(totalPages)">{{ totalPages }}</div>
      </li>

      <li class="pagination-item">
        <img
          class="next-icon"
          :class="{disabled: isInLastPage}"
          alt="previous"
          src="https://images.nav.by/img/cdn/system/design_system/chevron-right-black.svg"
          @click="onClickNextPage"
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AppPagination',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
  },
  emits: ['pagechanged'],
  data() {
    return {
      totalPages: Math.ceil(this.totalRows / this.perPage),
    };
  },
  computed: {
    firstVisiblePage() {
      return Math.max(1, this.currentPage - 2);
    },
    lastVisiblePage() {
      return Math.min(this.currentPage + 2, this.totalPages);
    },
    pages() {
      const range = [];
      for (let i = this.firstVisiblePage; i <= this.lastVisiblePage; ++i) {
        range.push({name: i, isDisabled: i === this.currentPage});
      }
      return range;
    },

    isFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  watch: {
    totalRows() {
      this.totalPages = Math.ceil(this.totalRows / this.perPage);
    },
  },
  methods: {
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-inline-start: 0;
}

.pagination-item {
  display: inline-block;
}

.active {
  background-color: #586EBF;
  color: var(--white-color);
}

.previous-icon, .next-icon {
  cursor: pointer;
  margin: 0 10px;
  display: unset;
}

.previous-icon.disabled, .next-icon.disabled {
  display: none;
}

.element-number-page {
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  padding-top: 4px;
  font-family: var(--header-font);
  font-size: 14px;
  width: 28px;
  height: 28px;
}

.last-item, .first-item {
  align-items: center;
  display: flex;
  gap: 10px;
}

.last-item {
  margin-left: 10px;
}

.first-item {
  margin-right: 10px;
}
</style>
