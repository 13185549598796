<template>
  <div class="main-div-app-select">
    <div class="toplabel">{{ toplabel }}</div>
    <app-select-users
      :placeholder="placeholder"
      :width="width"
      :value="value"
      :search-by-all-users="searchByAllUsers"
      :with-login="withLogin"
      :with-reset-button="withResetButton"
      :style="{position:'relative', top:'-20px'}"
      @change-action="$emit('changeAction', $event ? $event : null)"
    />
  </div>
</template>

<script>
export default {
  name: 'AppSelectUsersTopLabel',
  props: {
    toplabel: {
      type: String,
      required: true,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Выберите значение',
    },
    width: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, Array, Number],
      required: false,
    },
    searchByAllUsers: {
      type: Boolean,
      default: false,
    },
    withLogin: {
      type: Boolean,
      default: false,
    },
    withResetButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeAction'],

};
</script>

<style scoped>
.toplabel {
  background-color: white;
  width: auto !important;
  display: inline;
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #95A0AA;
  z-index: 6;
  margin-left: 5px;
  margin-top: -7px;
  padding: 0px 3px;
  height: 10px;
  top: -10px;
}

.main-div-app-select {
  padding-top: 7px;
  display: inline-block;
  text-align: left;
  max-height: 43px;
}

.main-div-app-select-inline {
  display: inline-block;
}

.main-div-app-select :deep(.multiselect__tag) {
  min-width: calc(100% - 10px);
  text-overflow: clip;
}

.main-div-app-select :deep(.my-custom-select) {
  z-index: auto;
}
</style>
