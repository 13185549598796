<template>
  <div
    ref="draggableContainer"
    class="dialog-container"
    :class="{draggable: !modal, 'without-padding': withoutPadding}"
    :style="{
      height: height ? (height + 'px') : 'fit-content',
      width: width ? (width + 'px') : 'auto',
    }"
  >
    <div class="draggable-element" @mousedown="dragMouseDown" />

    <div style="position:relative;">
      <img
        alt="Закрыть"
        title="Закрыть"
        class="close-icon"
        src="https://images.nav.by/img/cdn/system/design_system/close-grey.svg"
        @click="hideDialog"
      >
      <div class="dialog-header" @mousedown="dragMouseDown">
        {{ title }}
        <slot name="header" />
      </div>
    </div>

    <div
      ref="dialogContent" class="dialog-content" :class="{overflow: overflowAuto}"
      :style="{ maxHeight: maxDialogHeight + 'px'}"
    >
      <slot />
    </div>

    <slot name="footer" />
  </div>
</template>

<script>
export default {
  name: 'AppDialogContainer',
  props: {
    title: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: null,
    },
    width: {
      type: [Number, String],
      default: 500,
    },
    overflowAuto: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: true,
    },
    left: {
      type: String,
      default: null,
    },
    right: {
      type: String,
      default: null,
    },
    top: {
      type: String,
      default: null,
    },
    bottom: {
      type: String,
      default: null,
    },
    withoutPadding: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],

  data() {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
    };
  },

  computed: {
    maxDialogHeight() {
      return this.height ? this.height - 150 : window.innerHeight - 150;
    },
  },

  mounted() {
    if (!this.modal) {
      if (this.left) {
        this.$refs.draggableContainer.style.left = this.left;
        this.$refs.draggableContainer.style.right = 'unset';
      }

      if (this.right) {
        this.$refs.draggableContainer.style.right = this.right;
        this.$refs.draggableContainer.style.left = 'unset';
      }

      if (this.top) {
        this.$refs.draggableContainer.style.top = this.top;
        this.$refs.draggableContainer.style.bottom = 'unset';
      }

      if (this.bottom) {
        this.$refs.draggableContainer.style.bottom = this.bottom;
        this.$refs.draggableContainer.style.top = 'unset';
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit('close');
    },

    dragMouseDown(event) {
      if (this.modal) {
        return;
      }

      event.preventDefault();
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },

    elementDrag(event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      this.$refs.draggableContainer.style.top = `${Math.max(0, Math.min(window.innerHeight - this.$refs.draggableContainer.offsetHeight, this.$refs.draggableContainer.offsetTop - this.positions.movementY))}px`;
      this.$refs.draggableContainer.style.left = `${Math.max(0, Math.min(window.innerWidth - this.$refs.draggableContainer.offsetWidth, this.$refs.draggableContainer.offsetLeft - this.positions.movementX))}px`;
      this.$refs.draggableContainer.style.right = 'unset';
      this.$refs.draggableContainer.style.bottom = 'unset';
    },

    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
};
</script>

<style scoped>

.draggable {
  position: absolute;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
  z-index: 103900;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
}

.dialog-container {
  margin: auto;
  background: var(--white-color);
  border-radius: var(--border-radius);
  display: block !important;
  min-height: 50px;
  padding: 20px 30px;
  max-width: 95%;
}

.dialog-container.without-padding {
  padding: 20px 0 0;
}

.dialog-container.without-padding .close-icon {
  right: 0;
  top: -20px;
}

.dialog-content {
  margin: 20px 0;
}

.dialog-content.overflow {
  overflow-y: auto;
}

.dialog-header {
  text-align: center;
  font-family: var(--header-font);
  font-size: var(--header-font-size);
  font-weight: normal;
}

.close-icon {
  position: absolute;
  right: -20px;
  top: -15px;
  cursor: pointer;
}

.draggable-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
}

@media (max-width: 992px) {
  .dialog-container {
    padding: 10px 20px;
  }

  .close-icon {
    right: -20px;
    top: 0px;
  }
}
</style>
