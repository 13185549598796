<template>
  <input
    ref="input"
    type="text"
    :placeholder="placeholder"
    :value="modelValue"
    :style="styleInputText"
    :disabled="disabled"
    @input="updateInput"
    @blur="$emit('blur', $event)"
    @keydown.enter.prevent="enterAction"
    @keyup="keyUp"
    @click="click"
  >
</template>

<script>
export default {
  name: 'AppInputText',
  props: {
    modelValue: [String, Number],
    placeholder: String,
    styleInputText: [Object, String],
    disabled: Boolean,
  },
  emits: ['blur', 'enter', 'keyup', 'update:modelValue', 'inputAction'],
  methods: {
    enterAction(e) {
      const keyCode = e.which || e.keyCode;
      if (keyCode === 13 && !e.shiftKey) {
        this.$emit('enter');
      }
    },

    keyUp(event) {
      this.$emit('keyup', event);
    },

    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
      this.$emit('inputAction', event.target.value);
    },

    setFocus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style scoped>
input[type='text'], input[type='number'], input[type='time'], input[type='password'] {
    display: block;
    height: 30px;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

input[type='text']::placeholder, input[type='password']::placeholder {
    color: #ADB5BD;
    font-weight: 400;
}

input[type='text']:disabled{
  color: #B0B1B5;
}

input[type='text']:focus, input[type='password']:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0, 125, 255, 25%);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (max-width: 992px) {
    input[type='text'] {
        font-size: 16px;
    }
}
</style>
