<template>
  <vue-tel-input
    :ref="'phone-input-' + id"
    v-model="val"
    mode="international"
    default-country="BY"
    :valid-characters-only="true"
    :only-countries="onlyCountries"
    :input-options="{placeholder: 'Введите номер телефона'}"
    style="width: 100%"
    @blur="blur"
    @validate="update"
  />
</template>

<script>
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import {uuid} from '@/utils/commons';

export default {
  name: 'AppCountriesPhoneNumbers',
  components: {VueTelInput},
  props: {
    value: String,
  },
  emits: ['update'],
  data() {
    return {
      id: uuid(),
      val: this.value,
      countryCode: 'BY',
      onlyCountries: ['BY', 'RU', 'PL', 'LT', 'LV', 'KZ', 'UA'],
    };
  },

  watch: {
    value() {
      this.val = this.value;
    },
  },

  methods: {
    update(data) {
      this.val = data.number;
      this.$emit('update', data);
    },

    blur() {
      this.$emit('update', this.$refs['phone-input-' + this.id].phoneObject);
    }
  },
};
</script>

<style scoped>

</style>
