<template>
  <div class="main-editor-style">
    <div v-if="delimiter" class="dop-butt">
      <app-button minor small @click-action="insert()">Разрыв</app-button>
    </div>
    <div :id="containerId" :style="'min-height: 200px; height:'+(height?height:'calc(max(200px, 100% - 75px))')" />
    <div
      v-if="showImgSetting" class="img-setting"
      :style="{left:leftClickPosition+'px!important',top:topClickPosition+'px!important'}"
    >
      <div class="header">
        Свойства картинки
        <img
          class="close-icon" src="https://images.nav.by/img/cdn/system/design_system/close.svg"
          @click="showImgSetting=false"
        >
      </div>
      <div class="tbl">
        <div class="tr">
          <div class="td"><span>Путь</span></div>
          <div class="td flex5">
            <app-input-text v-model="imgSrc" class="img-src" />
          </div>
        </div>
        <div class="tr">
          <div class="td">
            <span>Ширина</span>
            <app-input-text v-model="imgWidth" style="width:70px" />
          </div>
          <div class="td">
            <app-button-bg small :inverse-color="checkSizePlus" @click-action="setSizePlus()">Размер +</app-button-bg>
          </div>
          <div class="td">
            <span>Высота</span>
            <app-input-text v-model="imgHeight" style="width:70px" />
          </div>
        </div>

        <div class="tr">
          <div class="td">
            <span>Выравнивание</span>
            <app-select
              :value="imgAlign" :options="alignList" track-by="val" label="val" style="width:90px"
              @change-action="imgAlign=$event"
            />
          </div>
        </div>
        <div class="tr">
          <div class="td">
            <app-button-bg inverse-color small @click="saveProp()">Применить</app-button-bg>
          </div>
          <div class="td">
            <app-button-bg inverse-color small @click="delImg()">Удалить картинку</app-button-bg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const colors = ['#c05488', '#246284', '#0b84dc', '#ffffff', '#ffffff', '#ffffff', '#000000',
  '#e60000', '#ff9900', '#ffff00', '#008a00',
  '#0066cc', '#9933ff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc',
  '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966',
  '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100',
  '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700',
  '#002966', '#3d1466'];

export default {
  name: 'NewsEditor',
  props: {
    height: null,
    modelValue: {
      default: '',
      type: String,
    },
    delimiter: {
      default: false,
      type: Boolean,
    },
    bucket: {
      default: 'public.beltranssat.by',
      type: String,
    },
    folder: {
      default: 'documentation',
      type: String,
    },
  },
  emits: ['inputAction'],
  data() {
    return {
      containerId: `editor-${Math.random().toString(36).substr(2, 9)}`,
      editorContent: null,
      cursorPosition: 0,
      reloadSource: false,

      showImgSetting: false,
      imgSrc: '',
      imgWidth: '',
      imgHeight: '',
      imgAlign: '',
      activeImg: null,
      leftClickPosition: 0,
      topClickPosition: 0,
      alignList: [{val: 'left'}, {val: 'center'}, {val: 'right'}],
    };
  },

  computed: {
    checkSizePlus() {
      const src = this.imgSrc;
      const m = src.match(/&?max_size=([0-9]*)/);
      if (m) {
        return String(m[1]) === String(Math.max(this.imgWidth, this.imgHeight));
      }
      return false;
    },
  },
  watch: {
    modelValue() {
      this.replaceOnLoad();

    },

    imgAlign() {
      if (this.imgAlign !== undefined) {
        let parent = this.activeImg;
        do {
          parent = parent.parentNode || parent.parentElement;
        } while (parent.tagName.toLowerCase() !== 'p');
        parent.style.textAlign = this.imgAlign.val;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.replaceOnLoad()
    }, 200);
    this.initializeEditor();
  },
  beforeUnmount() {
    Quill.find(document.querySelector(`#${this.containerId}`)).off('text-change');
    Quill.find(document.querySelector(`#${this.containerId}`)).off(Quill.events.EDITOR_CHANGE);
  },
  methods: {
    replaceOnLoad() {
      let newVal = this.modelValue.replace(/(<div style="page-break-after:always"><span style="display:none">&nbsp;<\/span><\/div>)/i, '<p>--------------------------Разрыв-----------------------</p>');
      if (newVal !== this.editorContent) {
        this.reloadSource = true;
        Quill.find(document.querySelector(`#${this.containerId}`)).pasteHTML(newVal);
        setTimeout(() => {
          this.reloadSource = false;
        }, 200);
      }
    },
    initializeEditor() {
      const editorOpts = {
        modules: {
          toolbar: {
            container: [
              [{header: [1, 2, 3, 4, 5, 6, false]}],
              [{font: ['Montserrat', 'Roboto']}],
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{list: 'ordered'}, {list: 'bullet'}, {align: []}],
              [{color: colors}, {background: colors}],
              ['clean'],
              ['link', 'image', 'video'],
              [{direction: 'rtl'}],
            ],
            handlers: {
              image: this.imageHandler,
            },
          },
        },
        theme: 'snow',
      };

      const AlignStyle = Quill.import('attributors/style/align');
      Quill.register(AlignStyle, true);

      const FontStyle = Quill.import('attributors/style/font');
      Quill.register(FontStyle, true);

      const Font = Quill.import('formats/font');
      Font.whitelist = ['Montserrat', 'Roboto'];
      Quill.register(Font, true);

      const container = document.getElementById(this.containerId);
      container.innerHTML = this.modelValue;
      const editorInstance = new Quill(container, editorOpts);

      editorInstance.on('text-change', this.onEditorContentChange);
      editorInstance.on(Quill.events.EDITOR_CHANGE, (eventType) => {
        if (eventType !== Quill.events.SELECTION_CHANGE) {
          return;
        }
        this.onClick();
      });
      this.setEditorContent();
    },
    onEditorContentChange() {
      this.setEditorContent();
      this.$emit('inputAction', this.prepareBeforeSave(this.editorContent));
    },
    setEditorContent() {
      const self = this;
      const images = document.querySelectorAll(`#${this.containerId} img`);
      images.forEach((image) => {
        image.addEventListener('contextmenu', (event) => {
          event.preventDefault();
          self.showProp(image, event);
        });
      });
      this.editorContent = Quill.find(document.querySelector(`#${this.containerId}`)).getText().trim() ? Quill.find(document.querySelector(`#${this.containerId}`)).root.innerHTML : '';
    },
    imageHandler() {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      input.onchange = async () => {
        const file = input.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('bucket', this.bucket);
        formData.append('folder', this.folder);

        let range = Quill.find(document.querySelector(`#${this.containerId}`)).getSelection(true);
        Quill.find(document.querySelector(`#${this.containerId}`)).insertEmbed(range.index, 'image', 'https://images.nav.by/img/cdn/admin/waiting.gif');
        range = Quill.find(document.querySelector(`#${this.containerId}`)).getSelection(true);

        await this.$postFile('/lumen/load_file/upload', formData)
            .then((response) => {
              Quill.find(document.querySelector(`#${this.containerId}`)).deleteText(range.index, 1);
              if (response.data.exitCode === 0) {
                Quill.find(document.querySelector(`#${this.containerId}`)).insertText(range.index, '\n');
                Quill.find(document.querySelector(`#${this.containerId}`)).setSelection(range.index + 1, 1);
                Quill.find(document.querySelector(`#${this.containerId}`)).insertEmbed(range.index, 'image', `/lumen/load_file/s3?bucket=${this.bucket}&fileKey=${response.data.fileKey}`);
                Quill.find(document.querySelector(`#${this.containerId}`)).deleteText(range.index + 1, 1);
                Quill.find(document.querySelector(`#${this.containerId}`)).setSelection(range.index + 1, 0);
              }
            });
      };
    },
    onClick() {
      if (Quill.find(document.querySelector(`#${this.containerId}`)).getSelection() && !this.reloadSource) {
        this.cursorPosition = Quill.find(document.querySelector(`#${this.containerId}`)).getSelection().index;
      } else if (this.reloadSource) {
        Quill.find(document.querySelector(`#${this.containerId}`)).setSelection(this.cursorPosition);
      }
    },
    insert() {
      Quill.find(document.querySelector(`#${this.containerId}`)).clipboard.dangerouslyPasteHTML(this.cursorPosition, '<p>--------------------------Разрыв-----------------------</p>');
    },
    prepareBeforeSave(txt) {
      return txt.replace(/(<p[^>]*>[-]{10,1000}Разрыв[-]{10,1000}<\/p>)/i, '<div style="page-break-after:always"><span style="display:none">&nbsp;</span></div>');
    },
    delImg() {
      const parent = this.activeImg.parentNode || this.activeImg.parentElement;
      parent.removeChild(this.activeImg);
      this.showImgSetting = false;
    },
    showProp(img, event) {
      let parent = img.parentNode || img.parentElement;
      if (parent.clientWidth < 100) {
        parent = parent.parentNode || parent.parentElement;
      }
      this.leftClickPosition = (event.layerX + 430 > parent.clientWidth && parent.clientWidth > 500) ? parent.clientWidth - 500 : event.layerX;
      this.topClickPosition = event.layerY;
      this.imgSrc = img.getAttribute('src');
      this.imgWidth = img.getAttribute('width');
      if (!this.imgWidth && img.style.width) {
        this.imgWidth = parseInt(img.style.width, 10);
      }
      if (!this.imgWidth && img.style.maxWidth) {
        this.imgWidth = parseInt(img.style.maxWidth, 10);
      }

      this.imgHeight = img.getAttribute('height');
      if (!this.imgHeight && img.style.height) {
        this.imgHeight = parseInt(img.style.height, 10);
      }
      if (!this.imgHeight && img.style.maxHeight) {
        this.imgHeight = parseInt(img.style.maxHeight, 10);
      }
      this.activeImg = img;

      parent = this.activeImg;
      do {
        parent = parent.parentNode || parent.parentElement;
      } while (parent.tagName.toLowerCase() !== 'p');

      this.imgAlign = this.alignList.find((item) => item.val.toLowerCase() === parent.style.textAlign.toLowerCase());
      this.showImgSetting = true;
    },
    saveProp() {
      this.activeImg.src = this.imgSrc;
      if (this.imgWidth) {
        this.activeImg.setAttribute('width', this.imgWidth);
      }
      if (this.imgHeight) {
        this.activeImg.setAttribute('height', this.imgHeight);
      }
      this.showImgSetting = false;
    },
    setSizePlus() {
      let src = this.imgSrc;
      const m = src.match(/(&?max_size=[0-9]*)/);
      if (m) {
        src = src.replace(m[1], '');
      }
      if (!m || m[1] !== `&max_size=${Math.max(this.imgWidth, this.imgHeight)}`) {
        this.imgSrc = `${src}&max_size=${Math.max(this.imgWidth, this.imgHeight)}`;
      } else {
        this.imgSrc = src;
      }
    },
  },
};
</script>

<style scoped>

.dop-butt {
  display: flex;
  align-items: center;

}

.dop-butt button:nth-child(2) {
  margin: 0 5px 0 25px;
}

.dop-butt input {
  max-width: 50px;
  margin-right: 3px;
}

.main-editor-style {
  overflow-y: auto;
  max-height: calc(100% - 0px);
  height: 100%;
  min-height: 200px;
  position: relative;
}

.main-editor-style :deep(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before),
.main-editor-style :deep(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before) {
  content: 'Montserrat';
}

.main-editor-style :deep(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Roboto]::before),
.main-editor-style :deep(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Roboto]::before) {
  content: 'Roboto';
}

.img-setting {
  background: var(--white-color);
  padding: 20px;
  box-shadow: var(--dialog-shadow);
  position: absolute;
  top: 0;
  left: 0;
  width: 450px;

  border: 1px solid var(--grey-color);
  border-radius: var(--border-radius);
}

.img-setting .img-src {
  min-width: 338px !important;
}

.img-setting td :deep(input[type="text"]) {
  min-width: 80px;
}

.td-name {
  width: 70px;
}

.header {
  font-weight: bold;
  font-family: var(--header-font);
}

.tbl {
  display: flex;
  flex-direction: column;
}

.tr {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  justify-content: space-between;
}

.td {
  display: flex;
  align-items: center;
}

.td span {
  margin-right: 5px;
  min-width: 65px;
}

.td :deep(button) {
  width: auto !important;
  padding: 0 10px;
}

</style>
