export function highlight(ref, color, duration) {
  const element = ref.$el || ref;
  element.style.transition = `background-color ${duration / 2}ms`;
  element.style.backgroundColor = color;
  setTimeout(() => {
    element.style.backgroundColor = '';
  }, duration / 2);
}

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
};