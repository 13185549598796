<template>
  <textarea
    ref="autoresizeTextarea"
    :placeholder="placeholder"
    :value="modelValue"
    :style="styleTextarea"
    :class="dragging ? 'drop-file' : ''"
    @drop.prevent="$emit('dropFile', $event); dragging = false"
    @dragleave="dragging = false"
    @dragover="dragging = true"
    @input="update($event.target.value); resize($event)"
    @keydown.enter="enterAction"
    @change="$emit('changeAction', $event.target.value); "
  />
</template>

<script>
export default {
  name: 'AppAutoresizeTextarea',
  props: {
    modelValue: String,
    placeholder: String,
    styleTextarea: [Object, String],
    isMobileVersion: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['dropFile', 'changeAction', 'enter', 'update:modelValue', 'inputAction'],

  data() {
    return {
      dragging: false,
      height: null,
    };
  },

  mounted() {
    this.height = this.modelValue === '' ? 'auto' : `${this.$refs.autoresizeTextarea.scrollHeight}px`;
  },

  methods: {
    resize(e) {
      this.height = e.target.value === '' ? 'auto' : `${e.target.scrollHeight}px`;
    },

    enterAction(e) {
      const keyCode = e.which || e.keyCode;

      if (keyCode === 13 && !e.shiftKey && !this.isMobileVersion) {
        e.preventDefault();
        this.$emit('enter');
      }
    },

    update(data) {
      this.$emit('update:modelValue', data);
      this.$emit('inputAction', data);
    },

    getValue() {
      return this.modelValue;
    },

    setFocus() {
      this.$refs.autoresizeTextarea.focus();
    }
  },
};
</script>

<style scoped>
textarea {
  padding: 5px 10px;
  box-sizing: border-box;
  font-size: 14px;
  resize: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  height: v-bind(height) !important;
  overflow: hidden;
}

textarea::placeholder {
  color: #ADB5BD;
  font-weight: 400;
}

textarea:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0, 125, 255, 25%);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.drop-file {
  border: #999 4px dashed !important;
}

@media (max-width: 992px) {
  textarea {
    font-size: 16px;
  }
}
</style>
