<template>
  <input
    :value="modelValue"
    type="time"
    :disabled="disabled"
    :step="step"
    @input="updateInput"
  >
</template>

<script>
export default {
  name: 'AppTimepicker',
  props: {
    modelValue: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 2,
    },
  },
  emits: ['update:modelValue', 'changeAction'],

  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
      this.$emit('changeAction', event.target.value);
    },
  },
};
</script>

<style scoped>
input[type="time"] {
  padding: 6px 10px;
  height: 30px;
  font-size: 14px;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width:100%;
}
</style>
