<template>
  <label class="wrapper flex items-center" :class="disabled?'wrapper-disabled':''">
    {{ label }}
    <input
      class="checkbox"
      type="radio"
      :disabled="disabled"
      :checked="isChecked"
      :value="value"
      @change="updateInput"
    >

    <span :class="'checkmark' + (small ? ' small' : '')" />
  </label>
</template>

<script>
export default {
  name: 'AppRadio',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    modelValue: [String, Number],
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'changeAction'],
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
      this.$emit('changeAction', event.target.value);
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  padding-top: 1px;
}

.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background-color: white;
  border: 1px solid #B0B1B5;
}

.checkmark.small {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background-color: white;
  border: 1px solid #B0B1B5;
}

.wrapper input:checked ~ .checkmark {
  border: 1px solid #1CA79F;
}

.wrapper:hover input ~ .checkmark {
  background-color: #1CA79F;
}

.wrapper input:checked ~ .checkmark {
  background-color: white;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.wrapper input:checked ~ .checkmark:after {
  display: block;
}

.wrapper .checkmark:after {
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #1CA79F;
}

.wrapper-disabled .checkmark:after {
    background: #aaaaaa!important;
}
.wrapper-disabled input:checked ~ .checkmark {
    border: 1px solid #aaaaaa!important;
}

.wrapper-disabled:hover input ~ .checkmark {
    background-color: white!important;
}

.wrapper {
  margin-bottom: 0;
}
</style>
