<template>
  <div>
    <div id="new-message-comment-wrap">
      <app-autoresize-textarea
        v-model="valueLocal"
        :is-mobile-version="isMobileVersion"
        :placeholder="placeholder"
        :style-textarea="styleTextarea"
        @drop-file="dropUploadFile"
        @enter="onEnter"
      />

      <a :style="'right: ' + (notShowSendButton ? 17 : 50) + 'px'" @click="chooseFiles">
        <input :ref="'filesNewMessage' + (isForeignClient ? 'Foreign' : '')" type="file" multiple hidden @change="updateFiles($event.target.files)">
        <img src="https://images.nav.by/img/cdn/admin/attach_file.svg">
      </a>
      <a v-if="!notShowSendButton" style="right: 17px;" @click="sendMessage"><img src="https://images.nav.by/img/cdn/admin/send.svg"></a>
    </div>

    <div v-if="newMessage.files" id="attach-files">
      <div v-for="(file, index) in newMessage.files" :key="index" class="attach-file">
        <span>{{ file.name }}</span>
        <a @click="deleteFile(index)"><img src="https://images.nav.by/img/cdn/system/design_system/delete.svg"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppMessageBlock',
  model: {
    prop: 'modelValue',
    event: 'valueChange',
  },
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: 'Введите сообщение',
    },
    styleTextarea: {
      type: String,
      required: false,
    },
    isForeignClient: {
      type: Boolean,
      required: false,
      default: false,
    },
    notShowSendButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    newMessage: {
      type: Object,
      required: true,
      default: () => {},
    },
    isMobileVersion: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'onEnter', 'sendMessage'],
  computed: {
    valueLocal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    dropUploadFile(ev) {
      ev.preventDefault();
      let dataTransfer;
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        dataTransfer = new DataTransfer();
        dataTransfer.items.add(ev.dataTransfer.files[i]);
        this.updateFiles(dataTransfer.files);
      }
    },
    onEnter(event) {
      this.$emit('onEnter', event);
    },
    chooseFiles() {
      this.$refs[`filesNewMessage${this.isForeignClient ? 'Foreign' : ''}`].click();
    },
    sendMessage(event) {
      this.$emit('sendMessage', event);
    },
    updateFiles(files) {
      const newFiles = [...files];
      // eslint-disable-next-line vue/no-mutating-props
      this.newMessage.files = this.newMessage.files.concat(newFiles);
      this.$refs[`filesNewMessage${this.isForeignClient ? 'Foreign' : ''}`].value = null;
    },
    deleteFile(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.newMessage.files.splice(index, 1);
    }
  },
};
</script>

<style scoped>
  #new-message-comment-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  #new-message-comment-wrap > a {
    position: absolute;
  }

  #attach-files {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .attach-file {
    padding-right: 10px;
    display: flex;
  }

  .attach-file span {
    color: #586EBF;
    vertical-align: text-bottom;
  }
</style>
