<template>
  <div class="container-app-table" :class="{'without-container-border': withoutContainerBorder}">
    <table
      class="app-table"
      :class="{
        'dark-header': darkHeader,
        'wo-border': withoutBorder,
        'hover': hover,
        'selectable': selectable,
        'without-border-header': withoutBorderHeader,
        'sticky-header': stickyHeader,
        'light-header': lightHeader,
        'striped': striped,
      }"
    >
      <thead v-if="!withoutHeader">
        <tr>
          <th
            v-for="field in fields"
            :style="field.thStyle || {}"
            :class="(field.class || '') + ' ' + (field.thClass || '')"
          >
            <div>
              <div>
                <span v-if="!field.sortable">
                  <slot
                    :name="'head(' + field.key + ')'"
                    v-bind="field"
                  >{{ field.label }}</slot>
                </span>
                <a v-else class="sortable" @click="actionSortColumn(field.key, field.sortType, field.sortByFormatted)">
                  <span>
                    <slot
                      :name="'head(' + field.key + ')'"
                      v-bind="{'field': field}"
                    >{{ field.label }}</slot>
                  </span>
                  <img class="sortable-img">
                </a>
              </div>
              <img
                v-if="field.title"
                class="icon-title"
                :title="field.title"
              >
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <slot name="top-row" />
        </tr>
        <template v-for="(item, index) in tableItems">
          <tr
            :class="(tbodyTrClass ? tbodyTrClass(item) : '') + ' ' + (rowSelectedClass && checkRowSelected(item) ? rowSelectedClass : '') + ' ' + (item['_showDetails'] === true ? 'table-has-details' : '')"
            :style="tbodyTrStyle ? tbodyTrStyle(item) : ''"
            v-bind="tbodyTrAttr ? tbodyTrAttr(item) : {}"
            @click="selectRow(item, index, $event)"
          >
            <td
              v-for="field in fields"
              :style="field.tdStyle || {}"
              :class="(field.class || '') + ' ' + (typeof field.tdClass === 'function' ? field.tdClass(field.key, item) : (field.tdClass || ''))"
            >
              <slot
                :name="'cell(' + field.key + ')'"
                v-bind="{'item': item, 'index': index, 'field': field}"
              >
                {{ field.formatter ? field.formatter(item[field.key]) : item[field.key] }}
              </slot>
            </td>
          </tr>

          <tr
            v-if="item['_showDetails'] === true"
            class="without-hover table-details"
            :class="(tbodyTrClass ? tbodyTrClass(item) : '')"
          >
            <td :colspan="fields.length">
              <slot name="row-details" v-bind="{'item': item, 'index': index}" />
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <slot name="tfoot" />
      </tfoot>
    </table>
  </div>
</template>

<script>

/**
 * PROPS:
 * items - массив для отображения
 *
 * fields - массив для хедера, может содержать следующие поля:
 * * key - ключ для поиска значения с массива items
 * * label - label для хедера
 * * title - передаем строку, тогда в хедере появится иконка с вопросиком, по наведению на который будет тайтл
 * * sortable - true - если нужна сортировка по полю
 * * sortType - string/number/date - если тип не указан, тогда сортировка определяется по типу поля
 * * class - если указан class, тогда он добавится и для td и для th
 * * tdClass - можно передать статический (строкой) для всех td, а можно передать callback в который будет возвращаться item и уже для конкретного td определять (пример tdClass: this.getCellClass, паметры функции 1 - ключ cell, 2 - item)
 * * thClass - статический класс строкой для всех th
 * * thStyle - стили для th, можно строкой, можно объектом thStyle: {width: '90px'} / thStyle: 'min-width: 150px'
 * * tdStyle - стили для td, можно строкой, можно объектом thStyle: {width: '90px'} / thStyle: 'min-width: 150px'
 * * formatter - например у нас в данных значение 7 (регион), а мы хотим его перед отображение обработать и вывести Минск, делаем функцию и передаем в callback (formatter: (regionId) => this.getRegionById(regionId))
 * * sortByFormatted - callback для сортировки, например у нас дата MM.DD.YYYY, а мы хотим сортировать по месяцу и и дню, пример: sortByFormatted: (date) => moment(date).format('MM.DD')
 *
 * per-page - если есть пагинация, количество объектов на странице,
 * current-page - если есть пагинация, текущая страница
 * sticky-header - если нужно закрепить хедер
 * dark-header - если хедер нужен темный
 * without-border - между ячейками (th/td) не будет бордера
 * without-border-header - между th не будет бордера
 * without-container-border - у контейнера не будет бордера
 * light-header - шапка будет белой, цвет подписи синий
 * hover - будет hover
 * striped - таблица будет зеброй
 * selectable - если нужна возможость выбирать строки
 *
 * select-mode - multi/range/single
 * * multi - можно выбирать несколько строк поочереди
 * * range - можно выбирать через SHIFT диапазон, через CTRL несколько записей
 * * single - одиночный выбор
 *
 * row-selected-class - можем передать строкой класс для selectable элементов и у себя через :deep обработать
 * tbody-tr-class - передаем callback для вычисления класса для определенного tr и у себя через :deep обработать, в callback передается item
 * tbody-tr-style - передаем callback для вычисления стилий для определенного tr, в callback передается item
 * tbody-tr-attr - передаем callback для атрибутов tr (title и др.)
 *
 * EMITS:
 * @row-selected - массив всех выбранных значений если таблица selectable
 * @row-clicked - передается один выбранный элемент
 *
 * SLOTS:
 * head(key) - если нужно добавить кастомное значение в th. Пример:
 *    <template #head(record)>
 *        Запись ({{ totalDurationCalls }})
 *    </template>
 *
 * cell(key) - если нужно добавить кастомное значение в td. Пример:
 *      <template #cell(id)="data">
 *        {{ data.item.id + ' (' + data.item.parent_id + ')' }}
 *      </template>
 *
 * row-details - если нужна раскрывающийся tr как в вопроснике, для этого в item должно быть значение _showDetails - true. Пример:
 *     <template #row-details="row">
 *        .....
 *     </template>
 *
 */


import {sortString, sortNumber, sortDate} from '../../utils/sort.js';

export default {
  name: 'AppTable',
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    perPage: Number,
    currentPage: Number,
    stickyHeader: Boolean,
    darkHeader: Boolean,
    withoutBorder: Boolean,
    withoutBorderHeader: Boolean,
    withoutContainerBorder: Boolean,
    withoutHeader: Boolean,
    lightHeader: Boolean,
    hover: Boolean,
    selectable: Boolean,
    striped: Boolean,
    rowSelectedClass: String,
    selectMode: {
      type: String,
      default: 'single',
    },
    tbodyTrClass: Function,
    tbodyTrStyle: Function,
    tbodyTrAttr: Function,
  },
emits: ['row-selected', 'row-clicked'],
  data() {
    return {
      sortColumn: null,
      sortDirection: null,
      sortType: null,
      sortByFormatted: null,
      selectedItems: [],
      lastRowIndexSelected: null
    };
  },
  computed: {
    tableItems() {
      let result = [];
      if(this.items != null && typeof this.items[Symbol.iterator] === 'function') {
        result = [...this.items];
      }

      if (this.sortColumn) {
        result.sort(this.sortFunction);
      }
      if (this.perPage && this.currentPage) {
        result = result.slice(this.perPage * (this.currentPage - 1), this.perPage * this.currentPage);
      }
      return result;
    },
  },
  methods: {
    actionSortColumn(key, sortType, sortByFormatted) {
      this.sortType = sortType || null;
      this.sortByFormatted = sortByFormatted || null;
      if (this.sortColumn === key) {
        this.sortDirection = this.sortDirection === 1 ? 0 : 1;
      } else {
        this.sortColumn = key;
        this.sortDirection = 0;
      }
    },

    sortFunction(a, b) {
      const firstValue = this.sortByFormatted ? this.sortByFormatted(a[this.sortColumn]) : a[this.sortColumn];
      const secondValue = this.sortByFormatted ? this.sortByFormatted(b[this.sortColumn]) : b[this.sortColumn];
      switch ((this.sortType || '').toLowerCase()) {
        case 'string':
          return this.sortDirection === 1 ? sortString(firstValue, secondValue) : sortString(secondValue, firstValue);
        case 'number':
          return this.sortDirection === 1 ? sortNumber(firstValue, secondValue) : sortNumber(secondValue, firstValue);
        case 'date':
          return this.sortDirection === 1 ? sortDate(firstValue, secondValue) : sortDate(secondValue, firstValue);
        default:
          if (typeof firstValue === 'string') {
            return this.sortDirection === 1 ? sortString(firstValue, secondValue) : sortString(secondValue, firstValue);
          } else {
            return this.sortDirection === 1 ? sortNumber(firstValue, secondValue) : sortNumber(secondValue, firstValue);
          }
      }
    },

    selectRow(item, index, e) {
      if (this.selectable) {
        switch (this.selectMode) {
          case 'multi':
            if (this.checkRowSelected(item)) {
              this.selectedItems = this.selectedItems.filter((element) => element !== item);
            } else {
              this.selectedItems.push(item);
            }
            break;
          case 'range':
            if (e.ctrlKey || e.metaKey) {
              if (this.checkRowSelected(item)) {
                this.selectedItems = this.selectedItems.filter((element) => element !== item);
                this.lastRowIndexSelected = null;
              } else {
                this.selectedItems.push(item);
                this.lastRowIndexSelected = index;
              }
            } else if (e.shiftKey && this.lastRowIndexSelected != null) {
              const startIndex = Math.min(this.lastRowIndexSelected, index);
              const endIndex = Math.max(this.lastRowIndexSelected, index) + 1;
              this.selectedItems = this.selectedItems.concat(this.tableItems.slice(startIndex, endIndex)).filter(this.onlyUnique);
            } else {
              if (this.checkRowSelected(item)) {
                this.selectedItems = [];
                this.lastRowIndexSelected = null;
              } else {
                this.selectedItems = [item];
                this.lastRowIndexSelected = index;
              }
            }
            break;
          default:
            this.checkRowSelected(item) ? this.selectedItems = [] : this.selectedItems = [item];
            break;
        }
        this.$emit('row-selected', this.selectedItems);
      }
      this.$emit('row-clicked', item, index);
    },

    onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    },

    checkRowSelected(item) {
      if (this.selectedItems.length === 0) {
        return false;
      }
      return this.selectedItems.find((element) => element === item) ? true : false;
    },

    clearSelected() {
      this.selectedItems = [];
      this.$emit('row-selected', this.selectedItems);
    },

    selectAllRows() {
      this.selectedItems = this.items;
      this.$emit('row-selected', this.selectedItems);
    },

    openFirstRow() {
      this.selectRow(this.tableItems[0], 0, {})
    }
  },
};
</script>

<style scoped>
.container-app-table {
  border-radius: 5px;
  border: 1px solid #c9c9c9;
  box-shadow: rgba(0, 0, 0, 0.13) 4px 0px 10px;
  overflow-y: auto;
  width: 100%;
}

.container-app-table.without-container-border {
  border: none;
}

.app-table {
  border: unset;
  border-collapse: collapse;
  background-color: white;
  border-spacing: 0;
  width: 100%;
}

.app-table thead th {
  background: #dee2f2;
  font-size: var(--main-font-size) !important;
  border: 1px solid var(--lightgrey-color);
  padding: 5px;
  font-weight: normal;
  border-radius: unset;
}

.container-app-table:not(.without-container-border) .app-table tbody td:first-child,
.container-app-table:not(.without-container-border) .app-table thead th:first-child {
  border-left: unset;
}

.container-app-table:not(.without-container-border) .app-table tbody td:last-child,
.container-app-table:not(.without-container-border) .app-table thead th:last-child {
  border-right: unset;
}

.app-table tbody td {
  font-size: var(--main-font-size);
  border: 1px solid var(--lightgrey-color);
  border-radius: unset;
  padding: 5px;
}

.app-table.dark-header thead th {
  background: #586ebf;
  color: var(--white-color) !important;
}

.app-table.light-header thead th {
  background: var(--white-color);
  color: #586EBF;
}

.app-table.striped tbody tr:not(.table-details):nth-child(2n+1) {
  background-color: #F1F3F8;
}

.app-table.wo-border td, .app-table.wo-border th {
  border: unset;
}

.app-table.wo-border tbody tr:not(:first-child):not(.table-details) {
  border-top: 1px solid var(--lightgrey-color);
}

.app-table.hover tr:hover:not(.without-hover) td {
  background: var(--lighblue-color) !important;
}

.app-table.selectable tr td {
  cursor: pointer;
}

.app-table.without-border-header th {
  border: unset;
}

.app-table th .sortable {
  color: unset;
  text-decoration: none;
  display: flex;
  gap: 5px;
}

.app-table th .sortable-img {
  content: url('https://images.nav.by/img/cdn/system/design_system/chevron-up-down-mini.svg');
}

.app-table.dark-header th .sortable-img {
  content: url('https://images.nav.by/img/cdn/system/design_system/chevron-up-down-mini-white.svg');
}

.app-table .text-center {
  vertical-align: center;
  text-align: center;
}

.app-table .relative {
  position: relative;
}

.app-table th > div {
  display: flex;
  gap: 5px
}

.app-table th.text-center > div {
  justify-content: center;
}

.app-table .icon-title {
  content: url('https://images.nav.by/img/cdn/system/design_system/question-24-blue.svg');
}

.app-table.dark-header .icon-title {
  content: url('https://images.nav.by/img/cdn/system/design_system/question-24-gray.svg');
}

.app-table.sticky-header thead {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>
