<template>
  <div style="display: inline-block; position: relative">
    <app-input-text
      ref="containerInputText"
      v-model="valueLocal"
      :placeholder="placeholder"
      style="padding-right: 30px; width: 100%"
      @enter="$emit('enter');"
      @input-action="$emit('inputAction', $event);"
    />
    <img style="position: absolute; right: 5px; top: 3px" src="https://images.nav.by/img/cdn/system/design_system/search-grey.svg">
  </div>
</template>

<script>
import AppInputText from './app-input-text.vue';

export default {
  name: 'AppInputSearch',
  components: {AppInputText},
  props: {
    modelValue: String,
    placeholder: String,
    setFocus: Boolean,
  },
  emits: ['update:modelValue', 'enter', 'inputAction'],
  computed: {
    valueLocal: {
      get() {
        return this.modelValue;
      },

      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  created() {
    if (this.setFocus) {
      this.setFocusFunction();
    }
  },
  methods: {
    setFocusFunction() {
      setTimeout(() => {
        this.$refs.containerInputText.setFocus();
      }, 300);
    },
  },
};
</script>
