<template>
  <div :class="toplabel===''?'main-div-app-select-inline':'main-div-app-select'">
    <div v-if="toplabel!=''" class="toplabel">{{ toplabel }}</div>
    <app-select
      v-model="selectedValue"
      :small="small"
      :options="options"
      :multiple="multiple"
      :deloldopt="deloldopt"
      :value="value"
      :dataid="dataid"
      :loading="loading"
      :label="label"
      :custom-label="customLabel"
      :searchable="searchable"
      :clear-on-select="clearOnSelect"
      :placeholder="placeholder"
      :close-on-select="closeOnSelect"
      :select-label="selectedLabel"
      :selected-label="selectedLabel"
      :deselect-label="deselectLabel"
      :show-labels="showLabels"
      :limit="limit"
      :track-by="trackBy"
      :limit-text="limitText"
      :style="{position:'relative', top:'-20px',width: width}"
      :preselect-first="preselectFirst"
      :allow-empty="allowEmpty"
      :group-values="groupValues"
      :group-label="groupLabel"
      :group-select="groupSelect"
      :colored="colored"
      :enable-select-all="enableSelectAll"
      @change-action="change"
    />
  </div>
</template>

<script>
import {isProxy, toRaw} from 'vue';

export default {
  name: 'AppSelectToplabel',
  props: {
    toplabel: {
      type: String,
      required: false,
      default: '',
    },
    resized: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [Object, Array, Number],
      default: null,
    },

    dataid: {
      type: String,
      default: 'id',
    },

    options: {
      type: Array,
      required: false,
    },

    label: {
      type: String,
    },

    customLabel: {
      type: Function,
      required: false,
    },

    searchable: {
      type: Boolean,
      default: false,
    },

    clearOnSelect: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: 'Выберите значение',
    },

    closeOnSelect: {
      type: Boolean,
      default: true,
    },

    selectLabel: {
      type: String,
      default: '',
    },

    selectedLabel: {
      type: String,
      default: '',
    },

    deselectLabel: {
      type: String,
      default: '',
    },

    showLabels: {
      type: Boolean,
      default: false,
    },

    limit: {
      type: Number,
      default: 1,
    },

    limitText: {
      type: Function,
      default: (count) => `и еще: ${count}`,
    },

    width: {
      type: String,
      default: '100%',
    },

    preselectFirst: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    trackBy: {
      type: String,
      default: '',
    },

    allowEmpty: {
      type: Boolean,
      default: true,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    deloldopt: {
      type: Boolean,
      default: false,
    },

    groupValues: {
      type: String,
    },

    groupLabel: {
      type: String,
    },

    groupSelect: {
      type: Boolean,
      default: false,
    },

    colored: {
      type: Boolean,
      default: false,
    },

    enableSelectAll: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeAction'],

  data() {
    return {
      selectedValue: typeof this.value === 'object' || Array.isArray(this.value) ? this.value : this.options.find((option) => parseInt(option[this.dataid], 10) === parseInt(this.value, 10)),
      calc_width: parseInt(this.width, 10),
    };
  },

  watch: {
    selectedValue() {
      this.$emit('changeAction', this.selectedValue);
    },
    value() {
      this.selectedValue = typeof this.value === 'object' || Array.isArray(this.value) ? this.value : this.options.find((option) => (option[this.dataid]) === (this.value));
    },
  },

  methods: {
    change(data) {
      this.$emit('changeAction', isProxy(data) ? toRaw(data) : data);
    },

    setValue(newVal) {
      this.selectedValue = newVal;
    },

    getValue() {
      return this.selectedValue;
    },

  },
};
</script>

<style scoped>

.hide {
  display: none;
}

.toplabel {
  background-color: white;
  width: auto !important;
  display: inline;
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #95A0AA;
  z-index: 100001;
  margin-left: 5px;
  margin-top: -7px;
  padding: 0px 3px;
  height: 10px;
  top: -10px;
}

.main-div-app-select {
  padding-top: 7px;
  display: inline-block;
  text-align: left;
  max-height: 43px;
}

.main-div-app-select-inline {
  display: inline-block;
}

.main-div-app-select :deep(.multiselect__tag) {
  min-width: 100%;
  text-overflow: ellipsis;
}

.main-div-app-select :deep(.my-custom-select) {
  z-index: 100000;
}

</style>
